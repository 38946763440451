import bookImg from '../../../../../assets/images/shopWeb/book.png';
import bookBinder from '../../../../../assets/images/book_binder.jpg';
import bookPdf from '../../../../../assets/images/shopWeb/Pdf_Image.png';

export const data = [
    {
        id: 1,
        name: "Personal Affairs Organizer Book",
        author: "Book",
        type: "book",
        price: 16.99,
        Descr1: "Version 2.0 ot KeysToMyWishes Personal Affairs Organizer ",
        Descr2: "Consists Ot 36 pages allowing you to enter your important information.",
        image: bookImg ,
        color : ['black' , 'pink' , 'purple' , 'white']
    },
    {
        id: 2,
        name: "Digital PDF Personal Affairs Organizer Book",
        author: "Digital PDF",
        type: "book",
        price: 12.99,
        Descr1: "Version 2.0 ot KeysToMyWishes Personal Affairs Organizer ",
        Descr2: "Consists Ot 36 pages allowing you to enter your important information. the Binder makes it easy to add more pages.",
        image: bookPdf,
        color : ['black' , 'pink' , 'purple' , 'white']
    },
    {
        id: 3,
        name: "Personal Affairs Organizer Binder",
        author: "Binder",
        type: "book",
        price: 26.99,
        Descr1: "Version 2.0 ot KeysToMyWishes Personal Affairs Organizer ",
        Descr2: "Consists Ot 36 pages allowing you to enter your important information. The Binder makes it easy to add more pages.",
        image: bookBinder ,
        color : ['black' , 'blue' , 'lightblue' , 'yellow', 'lightgreen', 'red']

    },
    {
        id: 4,
        name: "Yearly Digital Storage Subscription",
        author: "Yearly Subscription",
        type: "subscription",
        price: 60.00,
        Descr1: " Yearly Subscription of KeysToMyWishes Web App The app allows you to upload documents, database entries of your information Emergency Contacts, Your Wishes along with photos, videos.",
        image: "/logo.png"
    }
];
