import React from 'react';
import BGHeader from "../Common/BGHeader";
import Footer from "../Home/Footer";
import Introduction from "./Introduction";
import GetInTouch from "./GetInTouch";
import Location from "./Location";

const Contact = () => {
    return (
        <div>
            <BGHeader
                title={'Ready to secure your last wishes?'}
                description={'You deserve to live your days with peace of mind when it comes to securing your private and personal information. KeysToMyWishes is ready to give you that. '}
                style ={{width: "55vw"}}
            />
            {/*<Introduction/>*/}
            <GetInTouch/>
            <Location/>
            <Footer/>
        </div>
    );
};
    
export default Contact;