import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import KTMWOrganizer from './KTMW-Organizer.png';
import secureImg from '../../../../assets/images/homeWeb/secure.png';
import secureImg1 from '../../../../assets/images/homeWeb/secure1.png';
import './estimate-planning.css';
import '../../common.css';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BookIcon from '@mui/icons-material/Book';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {Card} from 'react-bootstrap';
import dotsImage from "../../../Landing/assets/images/dot.svg";
import linesImage from "../../../Landing/assets/images/lines.svg";
import WishesVideo from "../../../../assets/images/KeysToMyWishes.mp4";
import {wish} from "../../../../utils/wishes.utils";
import blackImg from '../../../../assets/images/black-book.png';
import pinkImg from '../../../../../src/assets/images/pink-book.png';
import purpleImg from '../../../../../src/assets/images/purple-book.png';
import whiteImg from '../../../../../src/assets/images/white-book.png';
// import Slider from 'react-slick';
import {useHistory} from "react-router-dom";


const EstatePlanning = () => {
    const history = useHistory();
    // const settings = {
    //     dots: false,
    //     arrows: false,
    //     infinite: true,
    //     speed: 800,
    //     autoplay: true,
    //     autoplaySpeed: 1500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1366,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //                 initialSlide: 1
    //             }
    //         },
    //         {
    //             breakpoint: 900,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 initialSlide: 1,
    //             }
    //         },
    //     ]
    // };
    return (
        <Container fluid className="p-0">
            <section className='section_container home_webcopy_section pb-3'>
                <Container>
                    <Row>
                        <Col lg={12} className='p-0 side_box_img'>
                            <Card className='helper_video_container'>
                                <Card.Body className='p-0'>
                                    <div className='helper_video_header'>
                                        <div className='d-flex justify-content-between'>
                                            <img
                                                src={dotsImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                            <img
                                                src={linesImage}
                                                className="d-inline-block align-top"
                                                alt="images"
                                            />
                                        </div>
                                    </div>
                                    <div className='helper_video_frame'>
                                        <div className='helper_video'>
                                            <video width="560" height="503" controls>
                                                <source src={WishesVideo} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className='side_box_section text-center'>
                            <div className='text-center'>
                                <h2 className='main_title'>Estate Planning Support</h2>
                                <p className='sub_title'>
                                    <span className='strong_title'>KeysToMyWishes</span> provides <b>all-in-one print
                                    and digital storage solutions</b> for your private
                                    information and <b>release of your last wishes</b>. So you can rest assured knowing
                                    your loved ones
                                    will be prepared to settle your affairs.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <div className="d-flex justify-content-center">
                    <img src={KTMWOrganizer} alt="KTMW Organizer" width="800px"/>
                </div>
            </section>
            <section className='section_container home_webcopy_section storage_section_container'>
                <Container>
                    <Row className="justify-content-center">
                        <div className='text-center'>
                            <h2 className='main_title'>Organization Support</h2>
                            <p className='sub_title mb-5'>
                                The KeysToMyWishes Personal Affairs Organizer is a comprehensive book that allows you to
                                keep your end-of-life information in one place. <span onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}>As seen on Amazon.</span>
                            </p>
                        </div>
                    </Row>
                    {/*<Row>*/}
                    {/*    <section className="custom_slider d-flex justify-content-center mt-5">*/}
                    {/*        <Col md={9}>*/}
                    {/*            <Slider {...settings}>*/}
                    {/*                <Col md={12} className="d-flex justify-content-center">*/}
                    {/*                    <img src={blackImg} alt={"Organizer"} height={200} width={200}/>*/}
                    {/*                </Col>*/}
                    {/*                <Col md={12} className="d-flex justify-content-center">*/}
                    {/*                    <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>*/}
                    {/*                </Col>*/}
                    {/*                <Col md={12} className="d-flex justify-content-center">*/}
                    {/*                    <img src={purpleImg} alt={"Organizer"} height={200} width={200}/>*/}
                    {/*                </Col>*/}
                    {/*                <Col md={12} className="d-flex justify-content-center">*/}
                    {/*                    <img src={whiteImg} alt={"Organizer"} height={200} width={200}/>*/}
                    {/*                </Col>*/}
                    {/*                <Col md={12} className="d-flex justify-content-center">*/}
                    {/*                    <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>*/}
                    {/*                </Col>*/}
                    {/*            </Slider>*/}
                    {/*        </Col>*/}
                    {/*    </section>*/}
                    {/*</Row>*/}
                    <Row className="justify-content-center">
                        <section className="book_section">
                            <Col md={9} className='book_main'>
                                <p className='single_img'>
                                    <img src={blackImg} alt={"Organizer"} height={200} width={200}/>
                                </p>
                                <p className='single_img'>
                                    <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>
                                </p>
                                <p className='single_img'>
                                    <img src={purpleImg} alt={"Organizer"} height={200} width={200}/>
                                </p>
                                <p className='single_img'>
                                    <img src={whiteImg} alt={"Organizer"} height={200} width={200}/>
                                </p>
                            </Col>
                        </section>
                    </Row>
                    <Row>
                        <section className="custom_slider_btn mt-2">
                        <div className='main_div'>
                                <button className="button_primary" onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}>
                                    Buy on Amazon
                                </button>
                                <button className="button_primary" onClick={() => history.push("/products")}>
                                    Buy here Now
                                </button>
                            </div>
                        </section>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Storage Peace of Mind</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Chuck the hesitation about uploading your private information online.
                                        </p>
                                        <p>
                                            Have a hard copy of your private information on hand, whenever you need it.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Organizational Bliss</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Keep everything your loved ones will need after you pass in one place.
                                        </p>
                                        <p>
                                            With 25 fillable templates, know exactly what documents you need to have in
                                            order for your loved ones to settle your affairs.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Retirement Relaxation</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Feel accomplished that you’ve done what you can to prepare your loved ones
                                            for your passing.
                                        </p>
                                        <p>
                                            Enjoy your retirement doing what you want instead of worrying about what
                                            will happen after you pass.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container support_section_container'>
                <Container>
                    <div className='text-center'>
                        <h2 className='main_title'>Storage + Organization Support</h2>
                        <p className='sub_title mb-5'>
                            Upload your personal information to our highly secure platform. Select the loved one you’d
                            like
                            your information to go to after you’ve become incapacitated or have passed. We’ll handle the
                            rest.
                        </p>
                    </div>
                    <Row>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Online Safety</h4>
                                    <div className='mt-4'>
                                        <p>
                                            Optional two-factor authentication for a safe log-in.
                                        </p>
                                        <p>
                                            256-bit encryption for airtight digital storage. <span
                                            className='strong_title'>KeysToMyWishes</span> won’t be able to see your
                                            private information.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card danger_card'>
                                <Card.Body>
                                    <h4>Ease of Use</h4>
                                    <div className='mt-4'>
                                        <p>
                                            A user-friendly app with simple upload of your private information.
                                        </p>
                                        <p>
                                            Biometric scan log-in. Say goodbye to remembering another password.
                                        </p>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className='support_card'>
                                <Card.Body>
                                    <h4>Comfort</h4>
                                    <div className='mt-4'>
                                        <p>
                                            2GB of storage space for all your important files and private information.
                                        </p>
                                        <p>
                                            Choice in how you want to store your information.
                                            Store the location of your KTMW Personal Affairs Organizer with us,
                                            or upload your private information directly to our encrypted platform.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className=' quote_section'>
                <Container fluid className='quote_box_container'>
                    <div className='quote_box_content text-center'>
                        <div className='avtar-img'>
                            <span>
                                GA
                            </span>
                        </div>
                        <div className='mt-4'>
                            <h4>
                                ...I am single and somewhat of a private person. Especially as it pertains to my
                                financial
                                affairs. I love the fact that I can simply make an entry into Keys with the location of
                                my
                                important documents without having to give this information to someone.
                            </h4>
                        </div>
                        <h6>-Greg A., KeysToMyWishes user</h6>
                        <FormatQuoteIcon className='quote_icon'/>
                    </div>
                </Container>
            </section>
            <section className='section_container secure_section'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className=''>
                                <h2 className='main_title'>Let us paint a picture for you.</h2>
                                <p className='sub_title'>
                                    Your loved ones receive word that you’ve passed. They’re now tasked with settling
                                    your affairs.
                                    They spend countless hours:
                                </p>
                            </div>
                            <div>
                                <ul className='for_you_list'>
                                    <li>
                                        <span><DocumentScannerIcon/></span>
                                        Looking for the key and password to <br/> your safe
                                    </li>
                                    <li>
                                        <span><InsertDriveFileIcon/></span>
                                        Digging through mountains of paperwork <br/> for important documents
                                    </li>
                                    <li>
                                        <span><BookIcon/></span>Flipping through notebooks in search <br/> of passwords
                                    </li>

                                    <li>
                                        <span><VerifiedUserIcon/></span>
                                        Sifting through files on hard drives, not <br/> sure what to make of any of it
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col lg={6}>
                            <img src={secureImg} alt='planningImg' className='img-fluid'/>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>

                        <Col lg={6} className='for_you_content_img mt-4'>
                            <img src={secureImg1} alt='planningImg' className='img-fluid'/>
                        </Col>

                        <Col lg={6} className='mt-4'>
                            <div className='for_you_content'>
                                <p>
                                    ...and more. You can’t control what happens after you pass, but you don’t want to
                                    add unnecessary
                                    frustration to the process. You want your loved ones to easily manage your affairs.
                                </p>
                                <p>What if you had <strong>one safe place</strong> to securely store your private
                                    end-of-life
                                    information?</p>
                                <p>A place that releases your information to your pre-designated loved ones upon your
                                    incapacitation
                                    or death.</p>
                                <p><span className='strong_title'>KeysToMyWishes</span> is ready to help you eliminate
                                    stress for your loved ones. <br/>
                                    You deserve to enjoy your retirement with <strong>peace of mind</strong>.</p>
                            </div>
                            <div className='mt-5'>
                                <button className='button_primary_outline'>
                                    Secure Your Information Now
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Container>
    );
};

export default EstatePlanning;